.Port_Layout{
    padding: 0 100px;
}

.Port_Layout .Acc_Select{
    background: transparent;
    border: 0;
    outline: none;
    margin-bottom: 20px;
    width: 300px;
    font-weight: bold;
    font-size: 110%;
}

.Chart_Port{
    display: flex;
    gap: 200px;
}

