.Inhalt{
    display: flex;
    flex-direction: row;
    gap: 6rem;
    overflow: auto;
    white-space: nowrap;
    margin-right: 4rem;
    margin-left: 3rem;
    border-bottom: 2px solid black;
}

.Graph{
    position: relative;
    align-items: center;
    position: relative;
    display: flex;
    left: 20px;
    width: 125px;
    height: 100px;
}