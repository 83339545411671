.News {
    display: block;
    width: 800px;
}

.News .Input{
    display: flex;
    margin-bottom: 30px;
}

.News .News_All{
    position: relative;
    overflow-y: auto;
    white-space: nowrap;
    height: 78vh;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.News .News_All .NewsItem{
    display: flex;
    flex-direction: column;
    color: black;
    text-decoration: none;
}


.News .News_All .NewsItem .NewsItem_Title a{
    color: black;
    text-decoration: none;
}

.News .News_All .NewsItem .NewsItem_Desc{
    font-size: 0.8em;
}
