.Deposit_Container{
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    height: 60px;
    width: 300px;
    gap: 15px;
}

.Deposit_Withdraw{
    display: flex;
    flex-direction: row;
}


.Deposit >button{
    width: 141px;
    height: 30px;
    background-color: rgba(0, 203, 57, .8);
    border-width: 0;
    border-radius: 4px;
    cursor: pointer;
}

.Withdraw >button{
    width: 141px;
    height: 30px;
    background-color: rgba(255, 78, 78, .9);
    border-width: 0;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
}

.Amount_Input{
    display: flex;
    flex-direction: row;
    background: rgba(38, 49, 60, .05);
    width: 270px;
    gap: 1rem;
    padding: 0 1rem;
}


.Amount_Input > input{
    width: 100%;
    background: transparent;
    border: 0;
    outline: none;
    height: 25px;
}