.BuySell_Box{
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    height: 100%;
    width: 320px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgba(38, 49, 60, .08);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .07);
    gap: 0rem;
}

.BuySell_Header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
}

.BuySell_Header .Button{
    display: flex;
    gap: 1.5rem;
}

.BuySell_Header .Button >button{
    background-color: transparent;
    border-width: 0;
    font-size: 1.25rem;
    cursor: pointer;
}

.BuySell_Header .Option >button{
    background-color: transparent;
    border-width: 0;
    font-size: 1.25rem;
    cursor: pointer;
}

