a.Navbar_Link{
    text-decoration: none;
    color: black;
}

a.Navbar_Link::after{
    content: '';
    display: block;
    margin: auto;
    height: 2px;
    width: 0px;
    background: transparent;
    transition: width .5s ease, background-color .5s ease;
}

a.Navbar_Link:hover::after{
    width: 100%;
    background: black;
}

.StockNews {
    padding-top: 20px;
    padding-bottom: 50px;
    width: 100%;
}

.StockNews .News_All{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.StockNews .News_All .NewsItem{
    display: flex;
    flex-direction: column;
    color: black;
    text-decoration: none;
}


.StockNews .News_All .NewsItem .NewsItem_Title a{
    color: black;
    text-decoration: none;
}

.StockNews .News_All .NewsItem .NewsItem_Desc{
    font-size: 0.8em;
}