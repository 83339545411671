.SearchBox {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 100px;
    padding-right: 100px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #EBEBEB;
    margin-bottom: 40px;
}
.Symbol{
    font-size: 250%;
    font-weight: bold;
    font-family: Snell Roundhand, cursive;
}

.Input{
    display: flex;
    flex-direction: row;
    background: rgba(38, 49, 60, .05);
    width: 24rem;
    gap: 1rem;
    padding: 0 1rem;
}

.Input .Icon{
    display: flex;
    align-items: center;
    justify-content: center;
}

.Input > input{
    width: 100%;
    background: transparent;
    border: 0;
    outline: none;
    height: 35px;
}

.Navigation{
    display: flex;
    gap: 2.5rem;
}