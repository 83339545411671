.Box{
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    height: 450px;
    width: 320px;
    gap: 40px;
    overflow: auto;
}

.Box_Pos{
    display: flex;
    flex-direction: column;
    width: 300px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgba(38, 49, 60, .08);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .07);
    gap: 20px;
    padding-bottom: 20px;
}


.Cash_Deposit{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 15px;
    border-bottom: 2px solid #aca4a4;
}

.Deposit{
    font-size: 120%;
    margin-right: 20px;
}

.Box_WL{
    display: flex;
    flex-direction: column;
    width: 300px;
    border-radius: 6px;
    border: 1px solid;
    border-color: rgba(38, 49, 60, .08);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .07);
    padding-bottom: 20px;
}
.Item_List{
    display: flex;
    flex-direction: column;
}

.Pos .Pos_Name{
    margin: 20px;
    margin-top: 0px;
}

.WL_Name{
    margin: 20px;
    margin-top: 20px;
}

.Item :hover {
    background: #b1b8be;
}

.Item >button{
    width: 100%;
    height: 50px;
    background-color: rgb(255, 255, 255);
    border-width: 0;
    border-radius: 4px;
    cursor: pointer;
}

.Item_Pos{
    padding-left: 20px;
    padding-right: 170px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.Item_Amount{
    border: 0.5px solid #aca4a4;
    border-radius: 4px;
    padding: 4px;
}

.Item_WL{
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}