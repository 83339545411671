.Metric_Container{
    display: Grid;
    width: 95%;
    column-gap: 40px;
    grid-template-columns: 1fr 1fr 1fr;
}

.Metric_Container .Metric1{
    margin: 0;
    display: grid;
    grid-template-columns: repeat(4,minmax(6rem,25%));
    grid-template-rows: repeat(3,1fr) auto;
    row-gap: 1.375rem;
    height: 100%;
}

.Metric_Container .Metric1 .Range{
    width: 100%;
    margin: 0;
    grid-column: 2/span 3;
}

.Metric_Container .Metric1 .Range .Range_Text{
    display: flex;
    justify-content: space-between;
}

.Metric_Container .Metric2{
    padding-left: 3rem;
    height: 100%;
    display: grid;
    grid: repeat(2,auto 1fr) .6875rem 1.25rem/auto-flow 1fr 1fr;
}

.Metric_Container .Metric3{
    height: 100%;
    display: grid;
    grid: repeat(2,auto 1fr) .6875rem 1.25rem/auto-flow 1fr 1fr;
}