.StockPrice{
    position: relative;
    align-items: center;
    position: relative;
    display: flex;
    flex-direction: column;
    left: 0px;
    width: 700px;
    height: 500px;
    gap: 1rem;
}