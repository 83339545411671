.Expense_Item{
    display:flex;
    justify-content: space-between;
    width: 500px;
    gap: 0rem;
    padding: 0.5rem;
    margin-bottom: 0.2rem;
}

.Expense_Item .Expense_Desc .Expense_Name {
    margin: 0;
    font-weight: normal;
  }

.Expense_Item .Expense_Desc .Expense_Tags{
    color: #555;
    font-size: 0.8rem;
}

.Expense_Item .Expense_Detail{
    display: block;
    text-align: right;
    width: 100%;
}

.Expense_Item .Expense_Detail .Expense_Cost{
    width: 100%;
}

.Expense_Item .Expense_Detail .Expense_Date{
    color: #555;
    font-size: 0.8rem;
}