.AI_Answer_AnswerBox {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: white;
    border: 2px solid #dfe1e5;
    border-radius: 24px;
    width: 1033px;
    margin-top: 1rem;
    padding-bottom: 20px;
}
.AI_Answer_AnswerBox .AI_Answer_Head{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0px;
    width: 90%;
    gap: 5rem;
}

