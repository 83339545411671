.News_Day{
    border-bottom: 1px solid;
    border-color: rgb(194, 190, 190);
}

.NewsItem{
    padding-left: 150px;
    margin-bottom: 20px;
}

.News_Desc{
    margin-top: 1px;
    font-size: 0.85em;
}
