.dashboard{
  background-color: #E0E0E0;
}

.layout{
  display: grid;
  grid-template-columns: 15% 85%;
  height:100vh;
  gap: 1rem;
}

.layout .MainLayout{
  width: 98.5%;
}

.MainLayout .Expense{
  margin-top: 50px;
}

.MainLayout .Fund{
  margin-top: 30px;
  margin-left: 20px;
  display:flex;
  width: 500px;
  height: 200px;
  gap: 3rem;
}

.MainLayout .Fund .Fund_Number{
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.brand{
  margin-top: 1rem;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.links li {
  justify-content: center;
  list-style-type: none;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  margin-top: 1.5rem;
}

.links a{
  color: black;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  gap: 0.5rem;
  display: inline-flex;
  align-items: center;
}

.layout .MainLayout .Stock_Content{
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-left: 20px;
  gap: 2rem;
}