.Port_Layout{
    padding: 0 100px;
}

.position{
    border-collapse: collapse;
    margin-top: 150px;
    width: 95%;
    margin-bottom: 100px;
}

.position >thead >tr >th{
    text-align: right;
    height: 70px;
    border: 1px solid;
    padding-left: 10px;
    padding-right: 10px;
    border-color: rgb(194, 190, 190);
}
.left_item{
    width: 140px;
    text-align: left;
}

.position >tbody >tr{
    border-bottom: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: rgb(194, 190, 190);
}

.position >tbody >tr >td{
    padding-left: 10px;
    padding-right: 10px;
}

.position >tbody >tr{
    text-align: right;
}
.symbol_name{
    text-align: left;
    border-right: 1px solid;
    border-color: rgb(194, 190, 190);
}

