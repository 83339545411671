.Stock_Layout{
    padding: 0 100px;
}

.Stock_Navbar{
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 40px;
    border-bottom: 1px solid #EBEBEB;
}