.Layout{
    margin-top: 5%;  
    margin-bottom: 5%;
    margin-left: 20%;
    margin-right: 20%;
}

@media (max-width: 768px) { 
    .Layout {
        margin-left: 5%;
        margin-right: 5%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) { 
    .Layout {
        margin-left: 10%;
        margin-right: 10%;
    }
}