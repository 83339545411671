.Stock_MainLayout{
    padding-bottom: 100px;
}

.Chart_BuySell{
    display: flex;
    gap: 200px;
}

a.Navbar_Link{
    text-decoration: none;
    color: black;
}

a.Navbar_Link::after{
    content: '';
    display: block;
    margin: auto;
    height: 2px;
    width: 0px;
    background: transparent;
    transition: width .5s ease, background-color .5s ease;
}

a.Navbar_Link:hover::after{
    width: 100%;
    background: black;
}

.Stock_TimeFrame{
    display: flex;
    gap: 10px;
}

.Stock_TimeFrame >button{
    background-color: transparent;
    border-width: 0;
}

.Chart_BuySell .Chart .Header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.Chart_BuySell .Chart .Header .Watchlist >button{
    background-color: transparent;
    border-width: 0;
    cursor: pointer;
    margin-top: 10px;
}

.Stock_Chart{
    display: flex;
    height: 400px;
    width: 100%;
}