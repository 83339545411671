.SellStock_Box{
    display: flex;
    flex-direction: column;
    background-color: #D3D3D3;
    border-radius: 10px;
    padding: 20px;
    gap: 2rem;
}

.SellStock_Box .SellStock_Input{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.Acc_Select{
    background: transparent;
    border: 0;
    outline: none;
    background-color: rgba(38, 49, 60, .05);
    height: 25px;
    border-radius: 4px;
    padding-left: 10px;
    width: 134px;
}

.BuyStock_Box .Order_sell{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    padding-bottom: 20px;
}

.Order_sell >button{
    width: 90%;
    height: 40px;
    background-color: rgba(255, 78, 78, .9);
    border-width: 0;
    border-radius: 4px;
    cursor: pointer;
}


/*
.Port_List_PW_List{
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.Port_List_PW_List .GraphBox{
    display: flex;
    justify-content: space-between;
    width: 250px;
    margin: 0 auto;
    color: black;
    text-transform: uppercase;
    text-decoration: none;
}

.Port_List_PW_List .GraphBox a{
    color: black;
    text-transform: uppercase;
    text-decoration: none;
}



.Port_List_PW_List .GraphBox .GraphLine{
    display: inline-block;
    width: 150px;
}
*/