.Calender{
    display: flex;
    flex-direction: row;
    width: 1250px;
    gap: 30px;
    height: 300px;
    margin-bottom: 50px;
}

.Weekly{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    white-space: nowrap;
    width: 400px;
    border-radius: 15px;
    border: 1px solid;
    border-color: rgba(38, 49, 60, .08);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .07);
    padding: 15px;
}

.daily .date{
    margin-top: 10px;
    font-weight: bold;
}

.rep{
    font-size: 0.9em;
}



.WeekName{
    border-bottom: 1px solid;
    border-color: rgb(194, 190, 190);
}

/*
.Calender{
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: auto;
    white-space: nowrap;
    height: 200px;
    width: 300px;
}
*/