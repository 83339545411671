.Progress{
    position: relative;
    display: flex;
    align-items: center;
}

.Progress .percent{
    position: relative;
    display: flex;
    left: 50px;
    width: 150px;
    height: 150px;
}

.Progress .percent svg circle{
    left: 200px;
    width: 400px;
    height: 150px;
    fill: none;
    stroke-width: 10;
    stroke: black;
    transform: translate(5px, 5px);
    stroke-dasharray: 440;
}

.Progress .percent svg circle:nth-child(1){
    stroke-dashoffset: 0;
    stroke: #f3f3f3;
}

.Progress .percent svg circle:nth-child(2){
    stroke: black;
    animation: animate 2s linear;
}

@keyframes animate{
    0%{
        stroke-dashoffset: 440;
    }
}

.Progress .number{
    font-size: 20px;
    position: absolute;
    display: flex;
    left: 101px;
}