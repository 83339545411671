.login{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login_container{
    margin-top: 30px;
    width: 300px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border: 1px solid;
    border-radius: 5px;
    padding: 20px;
}

.login .login_container > form > button{
    margin-right: 10px;
}

.login .login_container > form > input{
    margin-bottom: 15px;
    width: 90%;
}

