.Overview{
    display: flex;
    flex-direction: column;
}

.Overviw .Weekly{
    display: flex;
    flex-direction: row;
}

.daily .date{
    margin-top: 10px;
    font-weight: bold;
}

.rep{
    font-size: 0.9em;
}

.Calender{
    position: relative;
    overflow-y: auto;
    white-space: nowrap;
    height: 200px;
    width: 300px;
}