.AI_Search_SearchBox {
    display: flex;
    padding-top: 20px;
    padding-left: 100px;
    padding-right: 100px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.AI_Search_SearchBox .Input{
    display: flex;
    flex-direction: row;
    width: 100%;
    background: white;
    border: 2px solid #dfe1e5;
    border-radius: 24px;
    width: 1000px;
    height: 44px;
}

.AI_Search_SearchBox .Input .Icon{
    display: flex;
    align-items: center;
    justify-content: center;
}

.AI_Search_SearchBox .Input > input{
    width: 100%;
    background: transparent;
    border: 0;
    outline: none;
    height: 100%;
    font-size: 20px;
}


