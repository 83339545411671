.OrderLayout{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
}

.OrderForm{
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-between;
}

.OrderForm >dt{
    flex-basis: 33%;
    margin: 0.75rem 0 1.1875rem;
}

.OrderType{
    display: flex;
    flex-direction: row;
}

.OrderType >button{
    width: 67px;
    background-color: rgba(38, 49, 60, .05);
    border-width: 0;
    height: 30px;
    border-radius: 4px;
    cursor: pointer;
}

.OrderInput >input{
    width: 122px;
    border-width: 0;
    background-color: rgba(38, 49, 60, .05);
    height: 25px;
    outline: none;
    border-radius: 4px;
    text-align: right;
    font-size: inherit;
    padding-right: 10px;
}

.Order{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}

.Order >button{
    width: 90%;
    height: 40px;
    background-color: rgba(0, 203, 57, .8);
    border-width: 0;
    border-radius: 4px;
    cursor: pointer;
}

/*
.BuyStock_Box{
    display: flex;
    flex-direction: column;
    background-color: #D3D3D3;
    border-radius: 10px;
    padding: 20px;
    gap: 2rem;
}

.BuyStock_Box .BuyStock_Input{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
*/

/*
.Port_List_PW_List{
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.Port_List_PW_List .GraphBox{
    display: flex;
    justify-content: space-between;
    width: 250px;
    margin: 0 auto;
    color: black;
    text-transform: uppercase;
    text-decoration: none;
}

.Port_List_PW_List .GraphBox a{
    color: black;
    text-transform: uppercase;
    text-decoration: none;
}



.Port_List_PW_List .GraphBox .GraphLine{
    display: inline-block;
    width: 150px;
}
*/