.Port_List{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 300px;
    height: 700px;
    overflow-y:auto;
    margin-top: 50px;
    margin-left: 100px;
}

.Port_List .Port_List_PW{
    display: inline-block;
    background-color: #D3D3D3;
    border-radius: 25px;
    padding: 10px;
}


/*
.SmallGraph{
    display: flex;
    flex-direction: column;

    gap: 6rem;
    margin-right: 4rem;
    margin-left: 3rem;
}

.GraphLUL{
    display: inline-block;
}
*/

/*
.Inhalt{
    display: flex;
    flex-direction: row;
    gap: 6rem;
    overflow: auto;
    white-space: nowrap;
    margin-right: 4rem;
    margin-left: 3rem;
    border-bottom: 2px solid black;
}
*/