.Stock_MainLayout{
    padding-bottom: 100px;
}

.Chart_BuySell{
    display: flex;
    gap: 200px;
}

a.Navbar_Link{
    text-decoration: none;
    color: black;
}

a.Navbar_Link::after{
    content: '';
    display: block;
    margin: auto;
    height: 2px;
    width: 0px;
    background: transparent;
    transition: width .5s ease, background-color .5s ease;
}

a.Navbar_Link:hover::after{
    width: 100%;
    background: black;
}

.Stock_TimeFrame{
    display: flex;
    gap: 10px;
}

.Stock_TimeFrame >button{
    background-color: transparent;
    border-width: 0;
}

.Stock_Chart{
    display: flex;
    height: 400px;
    width: 100%;
}