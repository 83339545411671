.SellStock_Box{
    display: flex;
    flex-direction: column;
    background-color: #D3D3D3;
    border-radius: 10px;
    padding: 20px;
    gap: 2rem;
}

.SellStock_Box .SellStock_Input{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


/*
.Port_List_PW_List{
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.Port_List_PW_List .GraphBox{
    display: flex;
    justify-content: space-between;
    width: 250px;
    margin: 0 auto;
    color: black;
    text-transform: uppercase;
    text-decoration: none;
}

.Port_List_PW_List .GraphBox a{
    color: black;
    text-transform: uppercase;
    text-decoration: none;
}



.Port_List_PW_List .GraphBox .GraphLine{
    display: inline-block;
    width: 150px;
}
*/