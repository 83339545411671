.main_button {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main_button >button{    
    height: 40px;
    width: 35%;
    background-color: rgb(66, 62, 62);
    border-width: 0;
    border-radius: 4px;
    cursor: pointer;
    color: white;
    font-size: 20px;
}

@media (max-width: 768px) { 
    .main_button >button {
        width: 55%;
    }
}

.photo_container{
    display: flex;
    flex-direction: row;
}

@media (max-width: 768px) { 
    .photo_container{
        display: flex;
        flex-direction: column;
    }
}

.photo {
    width:95%;
    height: 100%;
    border: 2px solid #dfe1e5;
    border-radius: 24px;
}

.photo_2 {
    width:46%;
    height: 100%;
    border: 2px solid #dfe1e5;
    border-radius: 24px;
}

@media (max-width: 768px) { 
    .photo_2 {
        width:95%;
        height: 100%;
        border: 2px solid #dfe1e5;
        border-radius: 24px;
    }
}

.video {
    width:70%;
    height: 100%;
    border: 2px solid #dfe1e5;
    border-radius: 24px;
}

@media (max-width: 768px) { 
    .video {
        width:95%;
        height: 100%;
        border: 2px solid #dfe1e5;
        border-radius: 24px;
    }
}