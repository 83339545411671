.PortfolioLayout{
    height: 98%;
    width: 100%;
}

.PortfolioLayout .SearchBox{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 0px;
    height: 50px;
}

.PortfolioLayout .SearchBox .Input{
    background: #DEDEDE;
    color: #5a6674;
    font-size: 13px;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
}

.PortfolioLayout .PortGraph{
    display: flex;
    height: 87vh;
    width: 100%;
}

.PortfolioLayout .PortGraph .MainGraph{
    display: block;
}

.PortfolioLayout .PortGraph .BuySell{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 300px;
    height: 700px;
    overflow-y:auto;
    margin-top: 50px;
    margin-left: 100px;
}
